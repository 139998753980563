<template>
    <div class="page" id="threeList">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm" label-width="100px" @keyup.enter.native="search()">
            <el-form-item prop="title" label="关键字查询：">
                <el-input maxlength="100" v-model.trim="searchForm.title" placeholder="请输入资源名称或编号"
                          clearable></el-input>
            </el-form-item>
            <el-form-item prop="searchDates" label="更新时间：">
                <el-date-picker
                        v-model="searchForm.searchDates"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="更新起始时间"
                        end-placeholder="更新结束时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="search()" icon="el-icon-search">查询</el-button>
                <el-button @click="resetSearch()" icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
            <el-button class="f_r" type="primary" v-show="hasPermission('admin:resources:add')"
                       @click="add" size="small" icon="el-icon-plus">新增
            </el-button>
        </el-form>
        <div class="bg-white">
            <div class="flex_b_c">
                <AdvancedQuery ref="advancedQuery" :moduleId="moduleId"
                               @getDataList="atlGetDataList"></AdvancedQuery>
                <div>
                    <el-button v-show="hasPermission('admin:resources:submitCataloguing')"
                               @click="submitCatalog" size="small" plain icon="el-icon-position">提交编目</el-button>
                    <el-dropdown class="m_r1 m_l1">
                        <el-button size="small" plain icon="el-icon-news">智能操作</el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-show="hasPermission('admin:resources:uploadForm')"
                                              @click.native="uploadForm">上传表格
                            </el-dropdown-item>
                            <el-dropdown-item v-show="hasPermission('admin:resources:dataMatching')"
                                              @click.native="dataMatching">数据匹配
                            </el-dropdown-item>
                            <el-dropdown-item v-show="hasPermission('admin:resources:downloadForms')"
                                              @click.native="downloadForm">下载表格
                            </el-dropdown-item>
                            <el-dropdown-item v-show="hasPermission('admin:resources:downloadFiles')"
                                              @click.native="downloadFile">
                                下载文件
                            </el-dropdown-item>
                            <el-dropdown-item v-show="hasPermission('admin:resources:del')"
                                              @click.native="dels">批量删除
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-button size="small" plain icon="el-icon-setting" @click="setTb"></el-button>
                </div>
            </div>
            <el-table
                    :data="dataList"
                    v-loading="loading"
                    size="small" ref="tableBox"
                    height="calc(100vh - 328px)"
                    @selection-change="selectionChangeHandle"
                    class="table" row-key='id'>
                <el-table-column
                        type="selection"
                        width="50" fixed :reserve-selection='true'>
                </el-table-column>
                <el-table-column
                        prop="number" width="160"
                        show-overflow-tooltip sortable
                        label="数据分级">
                    <template slot-scope="scope">
                        <el-tag v-for="(item,index) in scope.row.models" :key="index" size="mini">{{item.fieldName}}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="thumb_path" width="110" label="缩略图">
                    <template slot-scope="scope">
                        <el-image style="width: 100px; height: 100px" :src="scope.row.thumb_path" fit="contain">
                            <el-image slot="placeholder" fit="contain" style="width: 100px; height: 100px"
                                      :src="require('../../../../../../assets/img/zhanwei.png')"></el-image>
                            <el-image slot="error" fit="contain" style="width: 100px; height: 100px"
                                      :src="require('../../../../../../assets/img/zhanwei.png')"></el-image>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column v-for="(item,index) in columnList" :key="index"
                                 :prop="item.fieldEname" min-width="160"
                                 show-overflow-tooltip sortable
                                 :label="item.fieldName">
                    <template slot-scope="scope">
                        <span v-if="item.fieldEname=='resource_type'">
                            {{$dictUtils.getDictLabel("resource_type",scope.row.resource_type,'-')}}
                        </span>
                        <span v-else-if="item.fieldEname=='resource_state'">
                            {{$dictUtils.getDictLabel("resource_state",scope.row.resource_state,'-')}}
                        </span>
                        <span v-else-if="item.fieldEname=='update_user'">
                                            {{scope.row.updateUserName}}
                                    </span>
                        <span v-else-if="item.fieldEname=='create_user'">
                                            {{scope.row.createUserName}}
                                    </span>
                        <span v-else>{{scope.row[item.fieldEname]}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        width="200"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" v-show="hasPermission('admin:resources:view')" @click="view(scope.row)">详情</el-button>
                        <el-button type="text" size="small" v-show="hasPermission('admin:resources:edit')" @click="edit(scope.row)">修改</el-button>
                        <el-button type="text" size="small" v-show="hasPermission('admin:resources:del')" @click="del(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    @size-change="sizeChangeHandle"
                    @current-change="currentChangeHandle"
                    :current-page="pageNo"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    :total="total"
                    background
                    layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
        <!--列表设置拖拽排序弹窗-->
        <DraggablePop :moduleId="moduleId" :setShow="setShow" @getTbList="getTbList"></DraggablePop>
        <!--新增-->
        <AddThreeForm ref="addThreeForm" @refreshDataList="refreshList"></AddThreeForm>
        <!--编辑-->
        <EditThreeForm ref="editThreeForm" @refreshDataList="refreshList"></EditThreeForm>
        <!--查看-->
        <ViewThreeForm ref="viewThreeForm"></ViewThreeForm>
        <!--上传表格-->
        <BulkImport ref="bulkImport" @refreshDataList="refreshList" :originalId="metadataId" :resourceType="0"
                    :resourceName="'三维资源'"></BulkImport>
        <!--数据匹配-->
        <DataMatching ref="dataMatching" @refreshDataList="refreshList"></DataMatching>
    </div>
</template>

<script>
    import DraggablePop from '@/components/draggable/draggablePop2' // 导入排序弹窗
    import AdvancedQuery from '@/components/advancedQuery/advancedQuery' // 导入高级筛选弹窗
    import AddThreeForm from './addThreeForm' // 新增弹窗
    import EditThreeForm from './editThreeForm' // 编辑弹窗
    import ViewThreeForm from './viewThreeForm' // 查看弹窗
    import BulkImport from '../components/bulkImport' // 上传表格弹窗
    import DataMatching from '../components/dataMatching' // 数据匹配弹窗
    export default {
        components: {DraggablePop, AdvancedQuery, AddThreeForm, EditThreeForm, ViewThreeForm, BulkImport, DataMatching},
        data() {
            return {
                searchForm: {
                    title: "",
                    searchDates: [],
                    scene: "",
                },
                sceneOpt: [
                    {
                        label: '全部',
                    },
                    {
                        label: '我上传的文件',
                    }
                ],
                dataList: [],
                columnList: [],
                pageNo: 0,
                pageSize: 10,
                total: 0,
                searchTpye: 0,
                highData: null,
                loading: false,
                dataListSelections: [],
                moduleId: "946049961995272192",   // 当前请求拖拽排序数据id
                setShow: false, // 是否显示列表设置拖拽排序弹窗
                metadataId: '',//元数据id
                onScene: false, // 是否正在使用场景
                dataListQuery: [], // 临时储存场景数据
            }
        },
        activated() {
            this.getColumnList()
            this.refreshList()
            this.getMetadataId();
        },
        watch: {
            searchDates() {
                if (this.searchDates) {
                    this.searchForm.beginDate = this.searchDates[0]
                    this.searchForm.endDate = this.searchDates[1]
                } else {
                    this.searchForm.beginDate = ''
                    this.searchForm.endDate = ''
                }
            }
        },
        methods: {
            // 获取数据列表
            refreshList() {
                if (this.onScene || (this.dataListQuery.queryConditions != null && this.dataListQuery.queryConditions.length > 0)) {
                    this.getDataList(this.dataListQuery)
                    return
                }
                //this.searchTpye = 0
                let startTime = null
                let endTime = null
                if (this.searchForm.searchDates != null) {
                    startTime = this.searchForm.searchDates[0]
                    endTime = this.searchForm.searchDates[1]
                }
                this.loading = true
                this.searchTpye = 0
                this.dataListQuery.sceneId = ''
                this.$axios(this.api.rcPage.ReCjList, {
                    'current': this.pageNo,
                    'size': this.pageSize,
                    'resourceNameOrId': this.searchForm.title,
                    'resourceType': 0,
                    'startTime': startTime,
                    'endTime': endTime,
                    'resourceState': 0
                }, 'post').then(res => {
                    this.dataList = res.data.records
                    this.total = parseInt(res.data.total);
                    this.loading = false
                })
            },
            //下载文件
            downloadFile() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let ids = this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$message.info('文件已开始下载，请勿重复操作')
                this.$axios(this.api.rcPage.downloadFile, {ids: ids, module: 0}, 'get', 'blob').then(res => {
                    let blob = new Blob([res.data], {type: 'application/zip'})
                    // let filename = res.headers['content-disposition']
                    let filename = '文件';
                    let link = document.createElement('a')
                    link.download = decodeURI(filename)
                    link.href = window.URL.createObjectURL(blob)
                    link.click()
                })
            },

            getMetadataId() {
                this.$axios(this.api.original.metaDataDirectoryList, {
                    'resourceName': '资源采集/上传表格',
                    'current': 1,
                    'size': 10,
                }, 'get').then(data => {
                    if (data.status) {
                        this.metadataId = data.data.records[0].id;
                    }
                })
            },

            // 获取显示字段
            getColumnList() {
                var that = this;
                this.columnList = [];
                that.$axios(this.api.sys.getByOriginalId + this.moduleId, '', "get").then((res) => {
                    if (res.status) {
                        this.columnList = []
                        res.data.map(item => {
                            if (item.logicType == 0) {
                                this.columnList.push(item);
                            }
                        })
                        this.$nextTick(() => {
                            this.$refs.tableBox.doLayout();
                        })
                    }
                })
            },
            // 新增
            add() {
                this.$refs.addThreeForm.init();
            },
            // 上传表格
            uploadForm() {
                this.$refs.bulkImport.init();
            },
            // 数据匹配
            dataMatching() {
                if(!this.dataList.length) {
                    this.$message.warning('暂无数据')
                    return
                }
                this.$refs.dataMatching.init('', 0);
            },
            // 查看
            view(row) {
                this.$refs.viewThreeForm.init(row);
            },
            // 编辑
            edit(row) {
                this.$refs.editThreeForm.init(row);
            },
            // 提交编目
            submitCatalog() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let ids = this.dataListSelections.map(item => {
                    return item.id
                })
                this.$confirm(`确定提交编目吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.resources.submitCatalog, {'ids': ids}, 'post').then(data => {
                        if (data && data.status) {
                            this.$message.success('提交成功')
                            this.refreshList()
                        } else {
                            this.$message('提交失败')
                        }
                    })
                })
            },
            // 查询
            getFileList() {
                // this.loading = true
                this.$axios(this.api.common.culturalRelicsList, {}, 'get').then(data => {
                    if (data && data.status) {

                    }
                })
            },
            // 高级查询
            atlGetDataList(data) {
                this.pageNo = 0
                this.getDataList(data)
            },

            // 获取高级查询条件
            getDataList(data) {
                if (data != null && data.sceneId != null && data.sceneId != '') {
                    this.onScene = true
                } else {
                    this.onScene = false
                }
                if (data.clearCurrent != null && data.clearCurrent == true) {
                    this.pageNo = 0
                }
                this.dataListQuery = data
                this.searchTpye = 0
                data.size = this.pageSize
                data.current = this.pageNo
                data.type = 0
                data.resourceState = 0
                data.searchNumOrName = this.searchForm.title
                data.startTime = this.searchForm.searchDates[0]
                data.endTime = this.searchForm.searchDates[1]

                this.$axios(this.api.rcPage.highQueryResource, JSON.stringify(data), 'post').then(res => {
                    if (res && res.status) {
                        this.dataList = res.data.records
                        this.total = parseInt(res.data.total);
                        this.$refs.tableBox.doLayout();
                    } else {
                        this.$message.error(res.msg)
                    }
                })

            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 删除
            dels() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let ids = this.dataListSelections.map(item => {
                    return item.id
                })
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.resources.delResources, {'ids': ids}, 'post').then(data => {
                        if (data && data.status) {
                            this.$message.success("删除成功")
                            this.refreshList()
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                })
            },
// 删除
            del(id) {
                let ids = new Array()
                ids.push(id)
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.resources.delResources, {'ids': ids}, 'post').then(data => {
                        if (data && data.status) {
                            this.$message.success("删除成功")
                            this.refreshList()
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                })
            },


            // 设置
            setTb() {
                this.setShow = true;
            },
            // 接收子组件（排序弹窗）传过来的值
            getTbList(data, state) {
                this.getColumnList()
                this.setShow = state;
            },
            // 每页数
            sizeChangeHandle(val) {
                this.pageSize = val
                this.pageNo = 1
                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    this.refreshList()
                }


            },
            // 当前页
            currentChangeHandle(val) {
                this.pageNo = val
                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    this.refreshList()
                }
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            resetSearch() {
                this.searchDates = []
                this.dataListQuery = []
                this.dataListSelections = []
                this.$refs.tableBox.clearSelection()
                this.$refs.advancedQuery.addForm.sceneId = ''
                this.$refs.searchForm.resetFields()
                this.$nextTick(() => {
                    this.onScene = false
                    this.pageNo = 0
                    this.refreshList()
                })
            },
            search() {
                this.pageNo = 0
                this.refreshList()
            },

            //下载表格
            downloadForm() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let ids = this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                let data = {
                    ids: ids,
                    originalId: this.moduleId
                }
                this.exportExcel(this.api.rcPage.exPortList, data, '三维资源列表', 'get')
            },
        }
    }
</script>
